<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="contractHeaders"
          :items="contracts"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr class="height" style="word-break: break-word">
              <td>{{ row.item.Owner.Name }}</td>
              <td>{{ row.item.Description }}</td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    selectedContract = row.item;
                    editButtonClick();
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    selectedContract = row.item;
                    confirmDeleteDialog = true;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-dialog v-model="editDialog" max-width="600">
          <v-card>
            <v-card-title class="pl-7 pa-3 headline grey lighten-2"
              >Edit Contract</v-card-title
            >
            <v-card-text class="pa-5">
              <v-row>
                <v-col cols="6" class="pl-6 pt-5">
                  Has the contract been checked?
                </v-col>
                <v-col cols="6">
                  <v-btn-toggle class="pl-8" v-model="newContract.Checked">
                    <v-btn block outlined text>Yes</v-btn>
                    <v-btn block outlined text>No</v-btn>
                  </v-btn-toggle>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    rows="1"
                    no-resize
                    v-model="newContract.Description"
                    label="Change Description"
                    color="red darken-2"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <OwnerSelector
                    :label="'Change Contract'"
                    :initialValue="selectedContract.Owner"
                    @update:owner="updateOwner"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn block dark class="red darken-1" @click="close"
                    >CANCEL</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    dark
                    class="red darken-1"
                    @click="editData(contracts, newContract)"
                    >SAVE</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDeleteDialog" max-width="320">
          <v-card>
            <v-card-title class="headline" style="word-break: break-word"
              >Confirm Contract delete</v-card-title
            >
            <v-card-text
              >Are you sure you would like to delete this Contract?
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                text
                @click="confirmDeleteDialog = false"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  confirmDeleteDialog = false;
                  deleteData(contracts, selectedContract);
                "
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import OwnerSelector from "@/components/OwnerSelector.vue";

export default {
  name: "App",
  props: {},
  components: {
    OwnerSelector,
  },

  data: () => ({
    editDialog: false,
    confirmDeleteDialog: false,

    contractHeaders: [
      { text: "Action Owner", value: "Owner", sortable: false },
      { text: "Description", value: "Description", sortable: false },
      { text: "", value: "", sortable: false },
      { text: "", value: "", sortable: false },
    ],

    selectedContract: {
      Id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      Checked: null,
      Description: null,
    },
    newContract: {
      Id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      Checked: null,
      Description: null,
    },
  }),

  methods: {
    close() {
      this.editDialog = false;
    },

    updateOwner(owner) {
      this.newContract.Owner = owner;
    },

    editButtonClick() {
      this.newContract = _.cloneDeep(this.selectedContract);

      if (this.newContract.Checked == "Yes") {
        this.newContract.Checked = 0;
      } else {
        this.newContract.Checked = 1;
      }

      this.editDialog = true;
    },

    editData(tableData, selectedObj) {
      const index = tableData.findIndex((x) => x.Id == selectedObj.Id);
      if (index > -1) {
        if (selectedObj.Checked == 0) {
          selectedObj.Checked = "Yes";
        } else {
          selectedObj.Checked = "No";
        }

        tableData[index].Owner = selectedObj.Owner;
        tableData[index].Checked = selectedObj.Checked;
        tableData[index].Description = selectedObj.Description;

        this.$store.state.prebidPlan.PreBidPlan.ContractActions = tableData;
      }
      this.editDialog = false;
    },

    deleteData(tableData, selectedObj) {
      tableData.splice(
        tableData.findIndex((e) => e.Id == selectedObj.Id),
        1
      );
      this.$store.state.prebidPlan.PreBidPlan.ContractActions = tableData;
    },
  },

  computed: {
    contracts() {
      return this.$store.state.prebidPlan.PreBidPlan.ContractActions;
    },
    owners() {
      return this.$store.state.prebidPlan.Owner;
    },
  },

  mounted() {},
};
</script>


