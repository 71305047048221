<template>
  <div class="v-navigation-bar">
    <ul>
      <li v-if="!isHome" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/' }">
          <i
            class="homeicon next"
            v-if="!isHome"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p :style="color">Home</p>
          <!-- <div ><i class="checkmark" v-if="!isHome"> </i> </div> -->
        </router-link>
      </li>

      <li v-if="isHome" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/' }">
          <i class="homeicon" v-if="isHome" @click="stepperOnClickHandler()">
          </i>
          <br />
          <p :style="color">Home</p>
          <!-- <div ><i class="checkmark" v-if="isHome"> </i> </div> -->
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isQuestionnaire" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidform' }">
          <i
            class="preparationicon next"
            v-if="!isQuestionnaire"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p :style="color">Questionnaire</p>
          <div><i class="checkmark" v-if="preparationComplete"> </i></div>
        </router-link>
      </li>

      <li v-if="isQuestionnaire" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidform' }">
          <i
            class="preparationicon"
            v-if="isQuestionnaire"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p :style="color">Questionnaire</p>
          <div><i class="checkmark" v-if="preparationComplete"> </i></div>
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isActions" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidactions' }">
          <i
            class="actions next"
            v-if="!isActions"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p :style="color">Actions</p>
          <div><i class="checkmark" v-if="actionsComplete"> </i></div>
        </router-link>
      </li>

      <li v-if="isActions" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidactions' }">
          <i class="actions" v-if="isActions" @click="stepperOnClickHandler()">
          </i>
          <br />
          <p :style="color">Actions</p>
          <div><i class="checkmark" v-if="actionsComplete"> </i></div>
        </router-link>
      </li>

      <li class="separator"></li>

      <li v-if="!isReport" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidreport' }">
          <i
            class="report next"
            v-if="!isReport"
            @click="stepperOnClickHandler()"
          >
          </i>
          <br />
          <p :style="color">Report</p>
        </router-link>
      </li>

      <li v-if="isReport" class="navigationTile">
        <router-link class="routerLink" :to="{ path: '/prebidreport' }">
          <i class="report" v-if="isReport" @click="stepperOnClickHandler()">
          </i>
          <br />
          <p :style="color">Report</p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import mixin from "../mixins/mixin.js";

export default {
  mixins: [mixin],
  components: {},
  data: () => ({
    drawer: true,
    loading: false,
    color: "color: #E61E28",
  }),

  //
  computed: {
    preparationComplete() {
      // return this.$store.state.plan.DigitalPlan.PreparationComplete;
      return false;
    },
    workshopComplete() {
      // return this.$store.state.plan.DigitalPlan.WorkshopComplete;
      return false;
    },
    preWorkComplete() {
      // return this.$store.state.plan.DigitalPlan.PreWorkComplete;
      return false;
    },
    actionsComplete() {
      // return this.$store.state.plan.DigitalPlan.ActionsComplete;
      return false;
    },
    isHome() {
      return this.$route.name === "Home";
    },
    isQuestionnaire() {
      return this.$route.name === "PrebidForm";
    },
    isActions() {
      return this.$route.name === "PrebidActions";
    },
    isReport() {
      return this.$route.name === "PrebidReport";
    },
  },

  mounted() {
    this.color = "color: #E61E28";
  },

  methods: {
    stepperOnClickHandler() {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
    },
  },
};
</script>

<style src="../styles/prebidnavbar.scss" lang="scss"></style>
