<template>
  <v-row>
    <v-col cols="12">
      <v-select
        label="Status"
        style="background-color: #f7f7f7"
        color="red darken-2"
        item-color="red darken-2"
        :items="status"
        v-model="currentStatus"
        @change="statusChange"
      >
        <template v-slot:prepend-inner>
          <v-icon>{{ currentIcon }}</v-icon>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "App",
  props: ["currentValue"],
  components: {},
  data: () => ({
    status: ["Not Started", "In Progress", "Blocked", "Completed"],
    icons: [
      "mdi-exclamation",
      "mdi-progress-clock",
      "mdi-block-helper",
      "mdi-check",
    ],
    currentStatus: "Not Started",
    currentIcon: "mdi-exclamation",
  }),

  methods: {
    statusChange() {
      console.log(this.currentStatus);
      for (const x in this.status) {
        if (this.status[x] == this.currentStatus) {
          this.currentIcon = this.icons[x];
          break;
        }
      }
    },
    update() {
      this.$emit("update:status", this.currentStatus);
    },
  },
  watch: {
    currentStatus: {
      handler() {
        this.update();
      },
    },
  },
  mounted() {
    this.update();

    this.currentStatus = this.currentValue;
  },
};
</script>

<style src="../styles/forms.scss" lang="scss"></style>
