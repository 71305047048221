<template>
  <div>
    <v-text-field
      readonly
      v-model="staffProxy.Name"
      :label="label"
      :autofocus="firstQuestion ? true : false"
      :color="prebid ? 'red darken-2' : null"
      @click="staffSelectorDialog = true"
    />
    <v-dialog v-model="staffSelectorDialog" width="500">
      <v-card class="removeScroll">
        <v-card-title
          v-if="label !== 'Digital Expert'"
          class="headline grey lighten-2"
        >
          Select an Action Owner
        </v-card-title>
        <v-card-title
          v-if="label == 'Digital Expert'"
          class="headline grey lighten-2"
        >
          Select an Digital Expert
        </v-card-title>
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12">
              <StaffSelect
                :prebid="true"
                :placeholder="dialogPlaceholder"
                :display="staffSelectorDialog"
                :model.sync="staffProxy"
                :dialog.sync="staffSelectorDialog"
                @selected="
                  staffSelectorDialog = false;
                  update();
                "
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StaffSelect from "@/components/StaffSelect.vue";
export default {
  components: {
    StaffSelect,
  },
  props: {
    initialValue: Object,
    label: String,
    placeholder: String,
    prebid: Boolean,
    firstQuestion: Boolean,
    reset: Boolean,
  },
  data: () => ({
    value: null,
    dialogPlaceholder: null,
    staffProxy: {
      Name: null,
      Email: null,
    },
    staffSelectorDialog: false,
    owners: [
      "Rio Aroun-Maxwell",
      "James O'Donnell",
      "Ben Hussey",
      "Emily Tinney",
      "Luca Lucoli",
      "Mitchell Harvey",
    ],
  }),
  methods: {
    placeholderText() {
      if (this.label == "Digital Expert") {
        this.dialogPlaceholder = "Record who you had the discussion with...";
      } else if (this.label == "Action Owner" || this.firstQuestion) {
        this.dialogPlaceholder = "Record who will be responsible...";
      } else {
        this.dialogPlaceholder = "Select a staff member...";
      }
    },

    autoPopulateOwners() {
      if (this.initialValue) {
        this.staffProxy = this.initialValue;
      }
    },

    update() {
      console.log(this.staffProxy);
      this.$emit("update:owner", this.staffProxy);
    },
  },

  mounted() {
    this.autoPopulateOwners();
    this.placeholderText();
  },

  watch: {
    model: {
      handler(newValue) {
        this.value = newValue;
      },
      immediate: true,
    },
    reset: {
      handler() {
        if (this.reset) {
          this.staffProxy = "";
          this.$emit("update:owner", "");
        }
      },
    },
    initialValue: {
      handler() {
        this.autoPopulateOwners();
      },
    },
  },

  created() {},
};
</script>
<style scoped>
.v-input__slot::before {
  border-style: none !important;
}
</style>
