<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="rogHeaders"
          :items="rogServices"
          hide-default-footer
          class="elevation-1"
          disable-pagination
        >
          <template v-slot:item="row">
            <tr class="height" style="word-break: break-word">
              <td>{{ row.item.Owner.Name }}</td>
              <td>{{ row.item.Description }}</td>
              <td>
                <StatusSelector
                  @update:status="updateStatus($event, row.item)"
                  v-bind:currentValue="row.item.Status"
                />
              </td>
              <td>
                <DueBy
                  @update:date="updateDate($event, row.item)"
                  v-bind:currentValue="row.item.Due"
                />
              </td>

              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    selectedService = row.item;
                    editButtonClick();
                  "
                  class="mdi-pencil"
                >
                </v-btn>
              </td>
              <td class="tableActions">
                <v-btn
                  icon
                  @click="
                    selectedService = row.item;
                    confirmDeleteDialog = true;
                  "
                  class="mdi-trash-can-outline"
                >
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>

        <v-dialog v-model="editDialog" max-width="600">
          <v-card>
            <v-card-title class="pl-7 pa-3 headline grey lighten-2"
              >Edit Service</v-card-title
            >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    rows="1"
                    no-resize
                    v-model="newService.Description"
                    label="Change Description"
                    color="red darken-2"
                  >
                  </v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <OwnerSelector
                    :label="'Change Service'"
                    :initialValue="selectedService.Owner"
                    @update:owner="updateOwner"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12"> </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-btn block dark class="red darken-1" @click="close"
                    >CANCEL</v-btn
                  >
                </v-col>
                <v-col cols="6">
                  <v-btn
                    block
                    dark
                    class="red darken-1"
                    @click="editData(rogServices, newService)"
                    >SAVE</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="confirmDeleteDialog" max-width="320">
          <v-card>
            <v-card-title class="headline" style="word-break: break-word"
              >Confirm Service delete</v-card-title
            >
            <v-card-text
              >Are you sure you would like to delete this service?
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red darken-3"
                text
                @click="confirmDeleteDialog = false"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="
                  confirmDeleteDialog = false;
                  deleteData(rogServices, selectedService);
                "
                >Delete</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import OwnerSelector from "@/components/OwnerSelector.vue";
import StatusSelector from "@/components/StatusSelector.vue";
import DueBy from "@/components/DueBy.vue";

export default {
  name: "App",
  props: {},
  components: {
    OwnerSelector,
    StatusSelector,
    DueBy,
  },

  data: () => ({
    editDialog: false,
    confirmDeleteDialog: false,

    rogHeaders: [
      { text: "Action Owner", value: "Owner", sortable: false },
      { text: "Description", value: "Description", sortable: false },
      { text: "Status", value: "Status", sortable: false },
      { text: "Due Date", value: "Due", sortable: false },
      { text: "", value: "", sortable: false },
      { text: "", value: "", sortable: false },
    ],

    selectedService: {
      Id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      Description: null,
      Description: null,
    },
    newService: {
      Id: null,
      Owner: {
        Name: null,
        Email: null,
      },
      Description: null,
      Description: null,
    },
  }),

  methods: {
    close() {
      this.editDialog = false;
    },

    updateOwner(owner) {
      this.newService.Owner = owner;
    },

    updateStatus(e, instance) {
      instance.Status = e;
    },

    updateDate(e, instance) {
      instance.Due = e;
    },

    editButtonClick() {
      this.newService = _.cloneDeep(this.selectedService);
      this.editDialog = true;
    },

    editData(tableData, selectedObj) {
      const index = tableData.findIndex((x) => x.Id == selectedObj.Id);
      if (index > -1) {
        tableData[index].Owner = selectedObj.Owner;
        tableData[index].Description = selectedObj.Description;

        this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices =
          tableData;
      }
      this.editDialog = false;
    },

    deleteData(tableData, selectedObj) {
      tableData.splice(
        tableData.findIndex((e) => e.Id == selectedObj.Id),
        1
      );
      this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices = tableData;
    },
  },

  computed: {
    rogServices() {
      return this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices;
    },
    owners() {
      return this.$store.state.prebidPlan.Owner;
    },
  },

  mounted() {},
};
</script>


