<template>
  <v-row>
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            style="background-color: #f7f7f7"
            color="red darken-2"
            item-color="red darken-2"
            label="Due By:"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker color="red darken-2" v-model="date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="red darken-2" @click="menu = false">
            Cancel
          </v-btn>
          <v-btn text color="red darken-2" @click="$refs.menu.save(date)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "App",
  props: ["currentValue"],
  components: {},
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
  }),

  methods: {
    update() {
      this.$emit("update:date", this.date);
    },
  },

  mounted() {
    this.update();

    this.date = this.currentValue;
  },

  watch: {
    date: {
      handler() {
        this.update();
      },
    },
  },
};
</script>
